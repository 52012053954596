import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import { BrowserRouter as Router, Link, } from "react-router-dom";
import Navbar from '../../Config/Navbar';
import '../vehicles/addvehicles.css'
import ApiUrlCall from '../API/apiurl';
import LinearProgress from '@material-ui/core/LinearProgress';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
import { withTranslation } from 'react-i18next';
import CommonFunction from '../common_function'
import Calendar from 'react-calendar/dist/entry.nostyle'

const Registration = "Registration certificate"
const Insurance = "insurance"
const Permit = "permit"
const Loan = "loan"
// const name_insurance = "name_insurance"
// const insurance_expiry = "insurance_expiry"
// const emi_amount = "emi_amount"
// const emi_end_date = "emi_end_date"

class EditVehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serial_number_is: this.props.location.state.serial_number,
            vehicleDetailsById: '',
            vehicle_name: '',
            vehicle_make: '',
            vehicle_model: '',
            vehicle_fuel_type: '',
            vehicle_variant: '',
            registration_number: '',
            vehicle_fuel_capacity: '',
            vehicle_vin: '',
            vehicle_odometer: '',
            vehicle_document_data: [],
            insurance_expiry_state: '',
            insurance_name_state: '',
            emi_amount_state: '',
            emi_end_date_state: '',
            showNext: false,
            servicingValue: 0,
            fuel_data: '',
            fuel_capacity: '',
            insuranceExpiry: '',
            loanExpiry: '',
            hardware : []
        }

    }

    componentDidMount() {
        analytics.logEvent('screen_view',{
            screen_name : "EditVehicle"
          })
          analytics.logEvent('page_view',{
            page_title : "EditVehicle",        
          })
          Amplify.configure(awswebapp);
          Analytics.record({
            name: constant.awsName, 
            // Attribute values must be strings
            attributes: { screen_name: 'WEB_EDIT_VEHICLE_DETAILS'}
          }); 
        var navBarHeight = $('.navBar').outerHeight();
        $('.create_new_vehicle').css({
            paddingTop: navBarHeight + 60,
            paddingLeft: "100px",
            paddingRight: "100px"
        })
        this.getVehicleDetails()
        this.getFuelDetails()
    }

    getFuelDetails = () => {
        var data = {serial_number : this.state.serial_number_is}
        ApiUrlCall.getFuelDetail(JSON.stringify(data)).then(response => {
            // console.log('data is', response)
            if(response.data.response_code === 200 && response.data.is_success){
                this.setState({
                    fuel_data : response.data.data
                })
            }
        }).catch(error => {
            console.log('error while fetching fuel data')
        })
    }

    selectType = (e) => {
        $(e.currentTarget).addClass('selected_btn')
        $(e.currentTarget).removeClass('unseleted_btn')
        $(e.currentTarget).siblings().removeClass('selected_btn')
        $(e.currentTarget).siblings().addClass('unseleted_btn')
    }

    previousView = (e) => {
        if ($('#enter_vehicle_details').is(':hidden')) {
            if (this.state.serial_number_is) {
                // console.log('get vehicle details')
                this.getVehicleDetails()
            }
            // $('.vehicle_header').toggle()
            // $('#enter_vehicle_details').show()
            // $('#enter_vehicle_document').hide()
            // $('#next_detail').show()
            // $('#add_details').hide()
        } else {

        }
    }

    nextView = (e) => {                 
        if (this.state.serial_number_is) {
            // console.log('put')
            this.addVehicle()
        }
        else {
            this.setState({
                showNext: false,
            })
            // console.log('post')
        }    
        // setTimeout(() => {
        //     console.log('nex show', this.state.showNext)          
        //     if(this.state.showNext){
        //         // $('#enter_vehicle_details').hide()
        //         // $('#enter_vehicle_document').show()
                
        //         // $('#next_detail').hide()
        //         // $('#add_details').show()
        //     }
        // },300)
    }

    removeFile = (e) => {
        $(e.currentTarget).parent().hide()
        $(e.currentTarget).parent().siblings().first().show()
        $(e.currentTarget).parent().prev().val("")
        var removeFileName = $(e.currentTarget).parent().prev().attr('name')
        var arrayOfData = this.state.vehicle_document_data
        var removeIndex = arrayOfData.findIndex(x => x.name === removeFileName)
        arrayOfData.map((items, index, arrayOfData) => {
            if (items.name === removeFileName) {
                arrayOfData.splice(removeIndex, 1)
                this.forceUpdate()
            } else {
                // console.log('in else condition')
            }
        })
        // console.log($(e.currentTarget).parent().prev(),';.;.;.;.;.; value')
    }

    fileUpload = (e) => {
        e.currentTarget.nextSibling.click()
    }

    fileAdded = (e) => {
        var uploadedFile = e.currentTarget.files[0].name
        var upladedImage = e.currentTarget.files[0]
        let fileReader = new FileReader();
        const owner_id = localStorage.getItem('user_id')
        let document_type = $(e.currentTarget).attr('name')
        $(e.currentTarget).prev().hide()
        $(e.currentTarget).next().show()
        $(e.currentTarget).next().find('label').empty()
        $(e.currentTarget).next().find('label').append(uploadedFile)
        fileReader.onloadend = (upladedImage) => {

            let documetData = (() => {
                switch (document_type) {
                    case Registration:
                        return { name: document_type,owner : owner_id, doc_type: 6,owner_type: 1, 
                            serial_number: this.state.serial_number_is, doc_file: fileReader.result }
                        break;
                    case Insurance:
                        return {
                            name: document_type, doc_type: 2,owner_type: 1,owner : owner_id,
                            name_insurance: this.state.insurance_name_state, 
                            end_date: CommonFunction.ServerDateTime(this.state.insuranceExpiry),
                            serial_number: this.state.serial_number_is,
                            doc_file: fileReader.result
                        }
                        break;
                    case Permit:
                        return { name: document_type, doc_type: 7,owner_type: 1,owner : owner_id,
                            serial_number: this.state.serial_number_is, doc_file: fileReader.result }
                        break;
                    case Loan:
                        return {
                            name: document_type, doc_type: 8,owner_type: 1,owner : owner_id,
                            emi_amount: this.state.emi_amount_state, 
                            end_date: this.state.emi_end_date_state,
                            serial_number: this.state.serial_number_is, 
                            doc_file: fileReader.result,
                        }
                        break;

                    default:
                        break;
                }
            })()
            this.state.vehicle_document_data.push(documetData)
            this.forceUpdate()
        }
        fileReader.readAsDataURL(upladedImage);
    }

    getVehicleDetails = () => {
        $('#processing').show()
        let serial_number = this.state.serial_number_is
        // console.log('serial number is', JSON.stringify(serial_number))
        ApiUrlCall.getVehicledetails(serial_number).then(response => {
            // console.log(response,'>>>>>>>>>>>>>>>>>')
            if (response.status === 200) {
                $('#processing').hide()
                this.setState({
                    vehicleDetailsById: response.data,
                    vehicle_make: response.data.make,
                    vehicle_model: response.data.model,
                    vehicle_name: response.data.name,
                    vehicle_vin: response.data.vehicle_identification_number,
                    vehicle_fuel_type: response.data.fuel_type,
                    registration_number: response.data.registration_number,
                    vehicle_id: response.data.serial_number,
                    vehicle_odometer: response.data.odometer,
                    showNext: false,
                    servicingValue: response.data.is_service,
                    fuel_capacity: response.data.fuel_capacity,
                    hardware : response.data.hw_attachment_list
                })
            }
        })
    }

    addVehicle = () => {
        var regex = new RegExp("^[A-Z|a-z]{2}\\s?[0-9]{1,2}\\s?[A-Z|a-z]{0,3}\\s?[0-9]{4}$")
        if (!regex.test(this.state.registration_number)) {
            alert("Kindly Enter Valid Registration Number")
            return
        }

        var vehicleDetails = {
            name: this.state.vehicle_name,
            registration_number: this.state.registration_number,
            owner: 3,
            serial_number: this.state.serial_number_is,
            type: 1,
            make: this.state.vehicle_make,
            model: this.state.vehicle_model,
            fuel_type: this.state.vehicle_fuel_type,
            vehicle_identification_number: this.state.vehicle_vin,
            is_primary: false,
            is_service : this.state.servicingValue,
            odometer : this.state.vehicle_odometer,
            fuel_capacity : this.state.fuel_capacity
        }
        var jsonVehicleData = JSON.stringify(vehicleDetails)
        // console.log('vehicle details is', jsonVehicleData)        
        ApiUrlCall.editVehicleDetails(jsonVehicleData).then(response => {
            if (response.status === 200) {
                this.setState({showNext: true}) 
                // console.log('response data', response)
            }
            // console.log('response after update', jsonVehicleData)
        }).catch(error => {                       
            console.log("error in update driver details", error.response)
        })
    }

    addDocument = () => {
        var vehicle_serial_number = this.state.serial_number_is
        var jsonDataArray = this.state.vehicle_document_data
        var counter = -1
        if(jsonDataArray.length > 0){
            for (let i = 0; i < jsonDataArray.length; i++) {
                counter = counter + 1
                // console.log('.........', i)
                $('#loading').slideDown(200, "swing")
                $('html, body').css({
                    overflow: 'auto',
                    height: '100%'
                });
                var documentJsonData = JSON.stringify(jsonDataArray[i])
                //console.log('document data is', documentJsonData)
                ApiUrlCall.addVehicleDocument(documentJsonData).then(response => {
                    if (response.status === 201) {
                        if (i === counter) {
                            $('#loading').fadeOut(200, "swing")
                            this.props.history.push({
                                pathname: 'list_vehicle'
                            })
                        }
                    } else {
                    }
                }).catch(error => {
                    console.log('error while adding the document of vehicle', error.response.data)
                })
            }
        }else{
            this.props.history.push({
                pathname: 'list_vehicle'
            })
        }

    }
    goBack = () => {
        this.props.history.push('list_vehicle')
    }

    getFuelType = (type) => {
        const {t} = this.props
        // console.log(type, "fuel type is")
        var fuel_type = [t('ct.gas'), t('ct.diesel'), t('ct.petrol'), t('ct.cng'), t('ct.ethanol'), t('ct.bio')][type]
        return fuel_type
      }

      ExpirydateClicked = date => {
        this.setState({
            insuranceExpiry : date
        })
        $('#start_date_cal').slideUp()
      }
      loandateClicked = date => {
        this.setState({
            loanExpiry : date
        })
        $('#loan_cal').slideUp()
      }
    render() {
        const {t} = this.props
        var minDate = new Date(); minDate.setDate(minDate.getDate() + 1);
        return (
            <div>
            <Navbar/>
            <div className="create_new_vehicle">
                <div>
                    <h3 className="page_header" >
                    <img src="images/arrow11.png" alt="icon" onClick={this.goBack}></img>
                        &nbsp;&nbsp;{t('evs.header')} : {this.state.vehicle_name}
                    </h3>
                </div>

                <div className="map_view_div">
                    <Grid container>
                        <Grid item xs>
                            <p className="p_header p_margin0" style={{ display : this.state.showNext ? 'none' : '' }}>{t('evs.basic')}</p>
                            <p className="p_header p_margin0" style={{ display : this.state.showNext ? '' : 'none' }}>{t('evs.doc')}</p>
                        </Grid>
                        <Grid item xs>
                        </Grid>
                    </Grid>
                </div>

                <div style={{ textAlign: "-webkit-right" }}>
                    <div className="prev_next_div">
                        <div>
                            <button className="btn previous_btn" onClick={this.previousView}>{t('aeds.previous')}</button>
                        </div>
                        <div>
                            <button id="next_detail" className="btn orange-btn" style={{ float: "none", display : this.state.showNext ? 'none' : '' }} onClick={this.nextView}>{t('aeds.next')}</button>
                            <button id="add_details" className="btn orange-btn" style={{ float: "none", display : this.state.showNext ? '' : 'none' }} onClick={this.addDocument}>{t('aeds.edit.save')}</button>
                        </div>
                    </div>
                </div>

                <div className="map_view_div vehicle_enter_detail">
                    {/* vehicle details */}
                    <div id="enter_vehicle_details" style={{ display: this.state.showNext ? 'none': '' }}>
                        <div>
                            <p className="p_header p_margin0">{t('evs.use')}</p>
                        </div>

                        <div className="vehile_uses" style={{ display: "none" }}>
                            <div onClick={this.selectType} className="selected_btn">
                                <p className="p_margin0">Dispatch</p>
                            </div>
                            <div onClick={this.selectType} className="unseleted_btn">
                                <p className="p_margin0">Infrastructure</p>
                            </div>
                            <div onClick={this.selectType} className="unseleted_btn">
                                <p className="p_margin0">Freight</p>
                            </div>
                        </div>

                        <div style={{marginBottom : "0px"}}>
                            <Grid spacing={4} container>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('evs.name')}</p>
                                    <input type="text" placeholder={t('evs.name')} className="vd_input" value={this.state.vehicle_name} onChange={
                                        (e) => {
                                            this.setState({ vehicle_name: e.currentTarget.value })
                                        }
                                    }></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('ct.make')}</p>
                                    <input type="text" placeholder={t('ct.make')} value={this.state.vehicle_make} className="vd_input" onChange={
                                        (e) => {
                                            this.setState({ vehicle_make: e.currentTarget.value })
                                        }
                                    }></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('ct.model')}</p>
                                    <input type="text" placeholder={t('ct.model')} className="vd_input" value={this.state.vehicle_model} onChange={
                                        (e) => {
                                            this.setState({ vehicle_model: e.currentTarget.value })
                                        }
                                    }></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('vds.type')}</p>
                                    {/* <input type="text" placeholder="Fuel Type" className="vd_input" defaultValue={this.getFuelType(this.state.vehicle_fuel_type)} disabled></input> */}
                                     <div className="input_div">
                                        <select className="custom_select" value={this.state.vehicle_fuel_type} style={{ padding: "15px", backgroundColor: "#eeeeee" }}
                                            onChange={(e) => {
                                                this.setState({
                                                    vehicle_fuel_type: e.currentTarget.value
                                                })
                                            }}>
                                            {/* <option>Select Fuel Type</option> */}
                                            <option value="0">{t('ct.gas')}</option>
                                            <option value="1">{t('ct.diesel')}</option>
                                            <option value="2">{t('ct.petrol')}</option>
                                            <option value="3">{t('ct.cng')}</option>
                                            <option value="4">{t('ct.ethanol')}</option>
                                            <option value="4">{t('ct.bio')}</option>
                                        </select>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('vds.reg.no')}</p>
                                    <input type="text" placeholder={t('vds.reg.no')} className="vd_input" value={this.state.registration_number} onChange={
                                        (e) => {
                                            this.setState({ registration_number: e.currentTarget.value })
                                        }
                                    }></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('vds.vin')}</p>
                                    <input type="text" placeholder={t('vds.vin')} className="vd_input" value={this.state.vehicle_vin} onChange={
                                        (e) => {
                                            this.setState({ vehicle_vin: e.currentTarget.value })
                                        }
                                    }></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('evs.odometer')}</p>
                                    <input type="text" placeholder={t('evs.odometer')} className="vd_input" value={this.state.vehicle_odometer} onChange={
                                        (e) => {
                                            this.setState({ vehicle_odometer: e.currentTarget.value })
                                        }
                                    }></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('evs.capacity')}</p>
                                    <input type="text" placeholder={t('evs.capacity')} className="vd_input" value={this.state.fuel_capacity} onChange={
                                        (e) => {
                                            this.setState({ fuel_capacity: e.currentTarget.value })
                                        }
                                    }></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">In Servicing</p>                                    
                                    <div className="input_div">
                                        <select className="custom_select" value={this.state.servicingValue} style={{ padding: "15px", backgroundColor: "#eeeeee" }}
                                            onChange={(e) => {
                                                this.setState({
                                                    servicingValue: e.currentTarget.value
                                                })
                                            }}>
                                            {/* <option>Select Fuel Type</option> */}
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>                                            
                                        </select>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{marginBottom : "0px", display : this.state.hardware.includes(18)? "" : "none"}}>
                            <hr/>
                            <p className="p_header">Fuel Details</p>
                            <Grid container spacing={5} style={{marginBottom : "0px"}}>
                                <Grid item xs style={{borderRight : "1px solid #cccccc"}}>
                                    <p className="cj_w_header"><b>{this.getFuelType(this.state.vehicle_fuel_type)}</b></p>
                                    <p className="cj_w_header">Fuel Type</p>
                                </Grid>
                                <Grid item xs style={{borderRight : "1px solid #cccccc"}}>
                                    <p className="cj_w_header"><b>{this.state.vehicleDetailsById.fuel_capacity} Liters</b></p>
                                    <p className="cj_w_header">Fuel Capacity</p>
                                </Grid>
                                <Grid item xs style={{borderRight : "1px solid #cccccc"}}>
                                    <p className="cj_w_header"><b>{this.state.vehicleDetailsById.fuel_level} Liters</b></p>
                                    <p className="cj_w_header">Current Fuel Level</p>
                                </Grid>
                                <Grid item xs style={{borderRight : "1px solid #cccccc"}}>
                                    <p className="cj_w_header"><b>{CommonFunction.dateFormate(this.state.vehicleDetailsById.fuel_refill_on)}</b></p>
                                    <p className="cj_w_header">Last Fuel Refill Done</p>
                                </Grid>
                                <Grid item xs>
                                    <p className="cj_w_header"><b>{CommonFunction.zeroAppend(this.state.vehicleDetailsById.avg_fuel_consume)} km/L</b></p>
                                    <p className="cj_w_header">Avg. Fuel Consumption</p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    {/* vehicle details end*/}

                    {/* enter vehilce document */}
                    <div id="enter_vehicle_document" style={{ display: this.state.showNext ? '': 'none' }}>
                        <Grid container spacing={4}>
                            <Grid item xs={2}>
                                <p className="p_header p_margin0">{t('evs.certificate')}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <div  onClick={this.fileUpload} style={{ marginTop: "0px" }}>
                                <img src="images/group-74.png"></img>
                                </div>
                                <input type="file" name={t('evs.certificate')} className="file_upload" onChange={this.fileAdded}></input>
                                <div className="show_file_name">
                                    <label className="p_margin0 cj_w_header">File name</label>
                                    <div class="remove_file" onClick={this.removeFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid spacing={4} container>
                            <Grid item xs={2}>
                                <p className="p_header p_margin0">{t('evs.in')}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">{t('evs.name.in')}</p>
                                <input type="text" placeholder={t('evs.name.in')} className="vd_input" onChange={(e) => {
                                    this.setState({
                                        insurance_name_state: e.currentTarget.value
                                    })
                                }}></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">{t('evs.exp.date')}</p>
                                {/* <input type="text" placeholder={t('evs.exp.date')} className="vd_input" disabled onClick={(e) => {
                                    console.log('expiry click')
                                    $('#start_date_cal').slideDown()
                                }}></input> */}
                                <div className="input_div" onClick={(e) => {
                                    // console.log('expiry click')
                                    $('#start_date_cal').slideToggle()
                                }}>
                                    <input type="text" placeholder={t('evs.exp.date')} id="start_date" disabled 
                                    value={CommonFunction.dateFormate(this.state.insuranceExpiry)} className="vd_input" style={{marginBottom : "0px"}}></input>
                                    <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                </div>
                                <div className="calendar_parent" id="start_date_cal">
                                    <Calendar
                                    selectRange={false}
                                    onChange={this.ExpirydateClicked}
                                    value={this.state.insuranceExpiry}                                    
                                    minDate={minDate}
                                    />
                                </div> 
                            </Grid>
                            <Grid item xs={2}>
                                <div  onClick={this.fileUpload} style={{ marginTop: "40px" }}>
                                <img src="images/group-74.png"></img>
                                </div>
                                <input type="file" name="insurance" className="file_upload" onChange={this.fileAdded}></input>
                                <div className="show_file_name" style={{ marginTop: "33px" }}>
                                    <label className="p_margin0 cj_w_header">File name</label>
                                    <div class="remove_file" onClick={this.removeFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid spacing={4} container>
                            <Grid item xs={2}>
                                <p className="p_header p_margin0">{t('evs.permit')}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <div  onClick={this.fileUpload} style={{ marginTop: "0px" }}>
                                <img src="images/group-74.png"></img>
                                </div>
                                <input type="file" name="permit" className="file_upload" onChange={this.fileAdded}></input>
                                <div className="show_file_name">
                                    <label className="p_margin0 cj_w_header">File name</label>
                                    <div class="remove_file" onClick={this.removeFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid spacing={4} container>
                            <Grid item xs={2}>
                                <p className="p_header p_margin0">{t('evs.loan')}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">{t('evs.emi.amt')}</p>
                                <input type="text" placeholder={t('evs.emi.amt')} className="vd_input" onChange={(e) => {
                                    this.setState({
                                        emi_amount_state: e.currentTarget.value
                                    })
                                }}></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">{t('ct.enddate')}</p>
                                <div className="input_div" onClick={(e) => {
                                    // console.log('expiry click')
                                    $('#loan_cal').slideToggle()
                                }}>
                                    <input type="text" placeholder={t('ct.enddate')} id="loan_date" disabled 
                                    value={CommonFunction.dateFormate(this.state.loanExpiry)} className="vd_input" style={{marginBottom : "0px"}}></input>
                                    <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                </div>
                                <div className="calendar_parent" id="loan_cal">
                                    <Calendar
                                    selectRange={false}
                                    onChange={this.loandateClicked}
                                    value={this.state.loanExpiry}                                    
                                    minDate={minDate}
                                    />
                                </div> 
                            </Grid>
                            <Grid item xs={2}>
                                <div  onClick={this.fileUpload} style={{ marginTop: "40px" }}>
                                <img src="images/group-74.png"></img>
                                </div>
                                <input type="file" name="loan" className="file_upload" onChange={this.fileAdded}></input>
                                <div className="show_file_name" style={{ marginTop: "33px" }}>
                                    <label className="p_margin0 cj_w_header">File name</label>
                                    <div class="remove_file" onClick={this.removeFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                                </div>
                            </Grid>
                        </Grid>

                        {/* <Grid container>
                        <Grid item xs={2}>
                            <p className="p_header p_margin0">permits</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Name of insurer</p>
                            <input type="text" placeholder="Fuel Capacity" className="vd_input"></input>
                        </Grid>                        
                        <Grid item xs={2}>
                            upload button
                        </Grid>
                   </Grid> */}
                    </div>
                    {/* enter vehilce document end */}
                </div>
                <div id="loading" className="modal_parent" style={{right:"0",textAlign:"center"}}>
                    <div className="modal_body">
                        <p className="modal_header">{t('aeds.upload')}</p>
                        <LinearProgress />
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default withTranslation()(EditVehicle);
